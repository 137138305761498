import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Background from "./backgroundImage"

const ClientsSection = () => {
  const data = useStaticQuery(graphql`
    query ClientsQuery {
      clients: contentfulHomePage {
        clientsTitle
        clients {
          id
          title
          file {
            url
          }
        }
      }
    }
  `)

  const { clientsTitle, clients } = data.clients

  return (
    <Background
      type="blue"
      style={{ backgroundSize: "cover" }}
      className="flex justify-center"
    >
      <div className="max-w-4xl w-full text-center py-8 md:py-20">
        <h1 className="uppercase font-bold text-white mb-8 text-3xl md:text-4xl siteFont">
          {clientsTitle}
        </h1>
        <div className="flex flex-wrap justify-center items-center">
          {clients.map(client => (
            <div key={client.id} className="w-2/5 md:w-3/12 mb-6">
              <img
                src={client.file.url}
                alt={client.title}
                className="w-10/12 mx-auto"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </Background>
  )
}

export default ClientsSection
