import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

// Components
import { Arrow } from "../icons"
import "./servicesList.scss"

const MobileView = ({ length, index, service }) => {
  const { id, title, description, image, btnText, btnLink } = service
  const last = length === index + 1
  return (
    <div
      className="flex flex-col items-center justify-center w-full text-center service-item sm:w-1/2 lg:w-1/3 md:px-2"
      key={id}
    >
      <div className="relative w-full h-full">
        <img
          src={image.file.url}
          alt={image.title}
          className="w-11/12 h-full mx-auto image"
          loading="lazy"
        />
      </div>
      <h2 className="text-xl font-bold text-white md:text-3xl siteFont">
        {title}
      </h2>
      <p className="w-full pt-2 text-greyTxt">{description}</p>
      <Link
        to={`/services/${btnLink}`}
        className="inline-block px-8 py-2 my-4 text-sm font-bold uppercase border rounded-full blueBtnBg btn text-primary md:my-4 focus:outline-none siteFont"
      >
        {btnText} {">>>"}
      </Link>
      {!last && <Arrow small className="arrow" />}
    </div>
  )
}
const DesktopView = ({ length, index, service, reverse }) => {
  const { id, title, description, imageHover, btnText, btnLink } = service
  const last = length === index + 1

  return (
    <div
      className={`relative my-8 service-item text-center w-full flex flex-col justify-center items-center ${
        reverse ? "flex-row-reverse" : ""
      }`}
      key={id}
    >
      <div className="relative z-10 w-full h-full md:w-3/5">
        <object
          alt={title}
          type="image/svg+xml"
          data={imageHover.file.url}
          className="w-4/5 h-full mx-auto cursor-pointer"
        >
          {imageHover.title}
        </object>
      </div>
      <div className="w-full md:w-2/5 md:flex md:flex-col md:items-center">
        <h2 className="text-xl font-bold text-white md:text-3xl siteFont">
          {title}
        </h2>
        <p className="w-full pt-2 text-xl text-center text-greyTxt">
          {description}
        </p>
        <Link
          className="inline-block px-8 py-2 mt-4 mb-12 text-sm font-bold uppercase border rounded-full blueBtnBg btn text-primary md:my-4 focus:outline-none siteFont"
          to={`/services/${btnLink}`}
        >
          {btnText} {">>>"}
        </Link>
      </div>
      {!last && <Arrow className="arrow" />}
    </div>
  )
}

const Services = ({ servicePage }) => {
  const data = useStaticQuery(graphql`
    query ServicesListQuery {
      servicesList: contentfulHomePage {
        services {
          id
          title
          description
          btnText
          btnLink
          image {
            title
            file {
              url
            }
          }
          imageHover {
            title
            file {
              url
            }
          }
        }
      }
    }
  `)

  const { services } = data.servicesList
  return (
    <div className="service-list">
      <div className="md:hidden">
        {services.map((service, index) => {
          return (
            <MobileView
              length={services.length}
              index={index}
              service={service}
            />
          )
        })}
      </div>
      <div className="flex-wrap justify-around hidden w-full md:flex md:flex-col">
        {services.map((service, index) => {
          return (
            <DesktopView
              length={services.length}
              index={index}
              service={service}
              reverse={index % 2 === 0 ? false : true}
            />
          )
        })}
      </div>
    </div>
  )
}

export default Services
