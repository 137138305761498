import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import RichTextRenderer from "./RichTextRenderer"
import Background from "./backgroundImage"
import Img from "gatsby-image"

const AwardSection = () => {
  const data = useStaticQuery(graphql`
    query awardsQuery {
      awards: contentfulHomePage {
        awardsTitle
        awardsDescription {
          json
        }
        awardsImage {
          title
          fluid(maxWidth: 790, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        awardsHoverImage {
          title
          fluid(maxWidth: 790, quality: 100) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        awardLink
      }
    }
  `)
  const {
    awardsTitle,
    awardsDescription,
    awardsImage,
    awardsHoverImage,
    awardLink,
  } = data.awards
  const [hover, setHover] = useState(false)
  return (
    <Background>
      <div className="awardSection max-w-6xl mx-auto px-4 md:px-8 xl:px-0 py-8 md:py-16">
        <div className="imageSection w-full md:w-3/5 mx-auto">
          <Link to={awardLink}>
            <Img
              fluid={awardsImage.fluid}
              alt={awardsImage.title}
              className={`image ${hover ? "active" : null}`}
              loading="lazy"
            />
            <Img
              fluid={awardsHoverImage.fluid}
              alt={awardsHoverImage.title}
              className={`imageHover ${hover ? "active" : null}`}
              loading="lazy"
            />
          </Link>
        </div>
        <h1 className="font-bold uppercase text-center text-white text-3xl md:text-4xl siteFont mt-4 md:mt-8">
          {awardsTitle}
        </h1>
        <div className="max-w-5xl mx-auto text-base md:text-lg mb-6 md:mb-8 mt-4 Gray">
          {RichTextRenderer(awardsDescription.json)}
        </div>
        <Link
          to={awardLink}
          className="btn inline-block md:block text-center text-white capitalize rounded-full text-lg md:text-2xl focus:outline-none py-4 px-8  md:w-1/4 lg:w-1/5 md:mx-auto bg-orange siteFont hover:bg-darkOrange transition duration-300 ease-in-out"
          onMouseEnter={e => setHover(true)}
          onMouseLeave={e => setHover(false)}
        >
          See Our Awards
        </Link>
      </div>
    </Background>
  )
}

export default AwardSection
