import React from "react"
import RichTextRenderer from "../RichTextRenderer"
import Services from "./servicesList"

const ServiceSection = ({ data }) => (
  <div className="serviceSection max-w-6xl mx-auto px-4 md:px-8 xl:px-0 pb-12 md:pb-0 md:py-12">
    <Services />
    <div className="text-center mx-auto md:w-4/5">
      <div className="text-white my-16 text-xl font-semibold w-full">
        {RichTextRenderer(data.json)}
      </div>
    </div>
    <hr className="md:mb-16" />
  </div>
)

export default ServiceSection
