import React from "react"
import Img from "gatsby-image"
import RichTextRenderer from "./RichTextRenderer"
import { useStaticQuery, graphql } from "gatsby"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query AboutQuery {
      about: contentfulHomePage {
        aboutTitle
        aboutDescription {
          json
        }
        aboutImage {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `)

  const { aboutTitle, aboutImage, aboutDescription } = data.about

  return (
    <div className="bg-white">
      <div className="max-w-6xl mx-auto md:flex md:justify-center px-4 md:px-8 xl:px-0 md:py-16 py-8">
        <div className="w-48 md:w-1/5 md:mr-6">
          <Img fluid={aboutImage.fluid} alt={aboutImage.title} loading="lazy" />
        </div>

        <div className="md:w-3/4 w-full">
          <h1 className="text-4xl font-bold uppercase my-4 md:mt-0 siteFont">
            {aboutTitle}
          </h1>
          <div className="text-darkTxt md:text-lg md:w-11/12">
            {RichTextRenderer(aboutDescription.json)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
