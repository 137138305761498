import React, { useState, useEffect } from "react"
import ReactRellax from "react-rellax"

//Components

//Assets and Styles
import Img1 from "../../assets/1.svg"
import Img2 from "../../assets/2.svg"
import Img3 from "../../assets/3.svg"
import Img4 from "../../assets/4.svg"
import Img5 from "../../assets/5.svg"
import Img6 from "../../assets/6.svg"

import Img1Mobile from "../../assets/1-mobile.svg"
import Img2Mobile from "../../assets/2-mobile.svg"
import Img3Mobile from "../../assets/3-mobile.svg"
import Img4Mobile from "../../assets/4-mobile.svg"
import Img5Mobile from "../../assets/5-mobile.svg"
import Img6Mobile from "../../assets/6-mobile.svg"

import "./banner.scss"

const Banner = () => {
  const [screenWidth, setScreenWidth] = useState(1024)
  useEffect(() => {
    const isBrowser = typeof window !== "undefined"
    if (isBrowser) setScreenWidth(window.screen.width)
  }, [])

  return (
    <div className="banner-container">
      <ReactRellax speed={-5}>
        <div>
          <object
            type="image/svg+xml"
            data={screenWidth > 767 ? Img6 : Img6Mobile}
            className="banner-container-image"
          ></object>
        </div>
      </ReactRellax>

      <ReactRellax speed={-3.8}>
        <img
          src={screenWidth > 767 ? Img5 : Img5Mobile}
          className="banner-container-image"
          loading="eager"
        />
      </ReactRellax>
      <ReactRellax speed={-1.8}>
        <object
          type="image/svg+xml"
          data={screenWidth > 767 ? Img4 : Img4Mobile}
          className="banner-container-image"
        ></object>
      </ReactRellax>
      <ReactRellax speed={-0.8}>
        <object
          type="image/svg+xml"
          data={screenWidth > 767 ? Img3 : Img3Mobile}
          className="banner-container-image"
        ></object>
      </ReactRellax>

      <ReactRellax speed={0.2}>
        <object
          type="image/svg+xml"
          data={screenWidth > 767 ? Img2 : Img2Mobile}
          className="banner-container-image"
        ></object>
        <div className="banner-container-image last-image">
          <img
            src={screenWidth > 767 ? Img1 : Img1Mobile}
            className="w-full"
            loading="eager"
          />
        </div>
      </ReactRellax>

      <ReactRellax speed={-1}>
        <div className="text-center text-white banner-content siteFont">
          <h1 className="w-full max-w-6xl px-2 mx-auto text-3xl font-semibold text-center md:text-6xl lg:font-bold md:px-0">
            Embark with Kokopelli
          </h1>
          <h2 className="text-2xl md:text-4xl">
            Denver Web Design &amp; Creative Agency
          </h2>
        </div>
      </ReactRellax>
    </div>
  )
}

export default Banner
