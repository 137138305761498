import React, { useState } from "react"
import Img from "gatsby-image"
import ReactPlayer from "react-player"

// Background Image component
import Background from "../backgroundImage"

// Icons
import { PlayBtn } from "../icons"

function Section({
  data: {
    goalTitle,
    goalMsg,
    videoUrl,
    vidTitle,
    description1,
    description2,
    ogImage,
  },
}) {
  const [playVid, setPlayVid] = useState(false)

  return (
    <div className="VideoSection">
      <div className="max-w-6xl mx-auto px-4 md:px-8 xl:px-0 text-center mb-12">
        <h2 className="text-white text-3xl font-semibold mb-3 siteFont">
          {goalTitle}
        </h2>
        <p className="w-full md:w-3/4 inline-flex text-greyTxt text-lg md:text-xl">
          {goalMsg}
        </p>
      </div>
      <Background type="greyInverse" className="video-div">
        <div className="max-w-6xl mx-auto px-4 md:px-8 xl:px-0">
          <div className="flex justify-center items-center relative">
            {playVid ? (
              <div className="videoPlayer">
                <ReactPlayer
                  playing={true}
                  url={videoUrl}
                  width="100%"
                  height="100%"
                />
              </div>
            ) : (
              <div
                className="video-wrapper relative w-11/12 md:w-3/4 cursor-pointer"
                onClick={() => setPlayVid(true)}
              >
                <Img
                  fluid={ogImage.fluid}
                  alt="Video thumbnail"
                  className="w-full rounded-lg"
                />
                <PlayBtn className="play-btn absolute  w-1/5 md:w-auto" />
              </div>
            )}
          </div>

          <h2 className="text-white text-2xl font-bold md:text-center mt-8 siteFont">
            {vidTitle}
          </h2>
          <div className="md:flex pt-8 pb-8 text-greyTxt w-full">
            <p className="md:mr-16 md:w-3/4 mb-4">{description1}</p>
            <p className="md:w-3/4 mb-4">{description2}</p>
          </div>
        </div>
      </Background>
    </div>
  )
}

export default Section
