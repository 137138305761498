import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Banner from "../components/Home/banner"
import ServicesSection from "../components/Home/servicesSection"
import VideoSection from "../components/Home/videoSection"
import Clients from "../components/clients"
import AboutUs from "../components/aboutUs"
import AwardSection from "../components/awardSection"
import WhyUs from "../components/whyUs"
import Slider from "../components/slider"
import ContactUs from "../components/contactUs"

const IndexPage = ({ data }) => {
  const {
    ogTitle,
    ogDescription,
    headline,
    headlineImage,
    details,
    goalTitle,
    goalMsg,
    videoUrl,
    vidTitle,
    description1,
    description2,
  } = data.home

  const { ogImage } = data.video

  const videoSectionData = {
    goalTitle,
    goalMsg,
    videoUrl,
    vidTitle,
    description1,
    description2,
    ogImage,
  }

  return (
    <Layout home>
      <SEO title={ogTitle || "Home"} description={ogDescription} />
      <Banner />
      <ServicesSection data={details} />
      <VideoSection data={videoSectionData} />
      <Clients />
      <Slider />
      <AboutUs />
      <AwardSection />
      <WhyUs />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery {
    home: contentfulHomePage {
      ogTitle
      ogDescription
      headline {
        json
      }
      headlineImage {
        title
        fluid(maxWidth: 1440, maxHeight: 450) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      details {
        json
      }
      goalTitle
      goalMsg
      videoUrl
      vidTitle
      description1
      description2
    }
    video: contentfulWebsiteLayout {
      ogImage {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default IndexPage
