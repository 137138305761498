import React from "react"
import Img from "gatsby-image"
import RichTextRenderer from "./RichTextRenderer"
import { useStaticQuery, graphql, Link } from "gatsby"
import Background from "./backgroundImage"

const WhyUs = () => {
  const data = useStaticQuery(graphql`
    query WhyUsQuery {
      whyUs: contentfulHomePage {
        whyTitle
        whyDescription {
          json
        }
        whyImage {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  `)

  const { whyTitle, whyImage, whyDescription } = data.whyUs
  return (
    <Background
      type="blue"
      style={{
        backgroundSize: "cover",
      }}
    >
      <div className="max-w-6xl mx-auto flex flex-col-reverse md:flex-row justify-center px-4 md:px-8 xl:px-0 md:pb-40 py-12">
        <div className="content-div text-white md:w-4/5 w-full">
          <h1 className="font-bold uppercase text-2xl md:text-4xl siteFont">
            {whyTitle}
          </h1>
          <div className="md:text-lg mb-8 mt-4">
            {RichTextRenderer(whyDescription.json)}
          </div>
          <Link
            to="/our-company"
            className="w-auto inline-block uppercase border rounded-full font-bold px-12 md:px-6 py-2 text-sm focus:outline-none siteFont blueBtnBg whyUsBtn"
          >
            Meet our team {">>>"}
          </Link>
        </div>
        <div className="w-full md:w-2/5">
          <Img
            fluid={whyImage.fluid}
            alt={whyImage.title}
            className="w-full"
            loading="lazy"
          />
        </div>
      </div>
    </Background>
  )
}

export default WhyUs
